/**
 * 为静态页面提供数据，然后使用 `<Head>` 等标签输出 SEO 信息
 * 
 * **此方法必须在 VUE setup 的顶层，必须 await 调用，不得包装在其它函数中使用！**
 * 
 * 示例
 * 
 * script setup 中
 * 
 * ```typescript
 * const seoData = await useStaticSeo('/');
 * ```
 * template 中
 * 
 * ```vue
 *  <Head>
 *     <Title>{{ seoData.title }}</Title>
 *     <Meta v-if="seoData.keywords" name="keywords" :content="seoData.keywords" />
 *     <Meta v-if="seoData.description" name="description" :content="seoData.description" />
 *   </Head>
 * ```
 * @param urlPath 静态页路径
 */
export async function useStaticSeo(urlPath: string) {
  const { data } = await useFetch('/api/front/seo/url', { params: { url: urlPath } });
  const seoData = computed(() => {
    if (data.value?.data) {
      return data.value.data;
    }
    return {
      title: 'MSBLUE® Official Site Buy loissanite Engagement & Wedding Rings–MSBLUE Jewelry',
      keywords: null,
      description: null
    }
  });
  return seoData;
}

/**
 * 为动态页面提供数据，然后使用 `<Head>` 等标签输出 SEO 信息
 * 
 * **此方法必须在 VUE setup 的顶层，必须 await 调用，不得包装在其它函数中使用！**
 * 
 * 示例
 * 
 * script setup 中
 * 
 * ```typescript
 * const seoData = await useTypeSeo('c', 'all');
 * ```
 * template 中
 * 
 * ```vue
 *  <Head>
 *     <Title>{{ seoData.title }}</Title>
 *     <Meta v-if="seoData.keywords" name="keywords" :content="seoData.keywords" />
 *     <Meta v-if="seoData.description" name="description" :content="seoData.description" />
 *   </Head>
 * ```
 * @param type 类型，必须提供
 * @param uc 路由中的组件参数
 */
export async function useTypeSeo(type: 'c' | 'f' | 'n', uc: string) {
  const { data } = await useFetch(`/api/front/seo/${type}/uc`, { params: { uc } });
  const seoData = computed(() => {
    if (data.value?.data) {
      return data.value.data;
    }
    return {
      title: 'MSBLUE® Official Site Buy loissanite Engagement & Wedding Rings–MSBLUE Jewelry',
      keywords: null,
      description: null
    }
  });
  return seoData;
}